html,
body {
  background-color: #14141c;
}

.navbar-item img {
  max-height: none;
}

.FormRow.stripe {
  margin-bottom: 10px;
}

.FormRow.stripe:last-child {
  margin-bottom: 0px;
}

.navbar {
  background-color: #14141c;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  border-color: #424242;
  width: 100%;
  padding: 11px 10px 11px 10px;
}

.FormGroup.card {
  margin: 10px 0px 10px 0px;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  background-color: white;
  border: 1px solid transparent;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636;
}
